import React from 'react';

import PropTypes from 'prop-types';

import { ReactHookFormFroala } from 'common/components/form/bootstrap';

const TabConditions = ({ control }) => (
    <ReactHookFormFroala control={control} name="considerations" />
);

TabConditions.propTypes = { control: PropTypes.object };

export default TabConditions;

import get from 'lodash/get';

import uploadFilesLisa from 'common/modules/lisaFiles/actions/uploadFiles';

import { fetchProjects, fetchProjectDetails } from '../../project/actions';
import * as selectors from '../selectors';

import selectProject from './selectProject';

export default (files, overwrite = true) =>
    (dispatch, getState) => {
        const project = selectors.getSelectedProject(getState());

        if (!files?.length || !project) return;

        dispatch(
            uploadFilesLisa({
                files,
                onUpdatedProject: (updatedProject) => {
                    dispatch(selectProject(updatedProject));
                    dispatch(
                        fetchProjects({
                            contactId: get(project, 'contact.id', null),
                        })
                    );
                    dispatch(fetchProjectDetails(updatedProject.id));
                },
                overwrite,
                project,
            })
        );
    };

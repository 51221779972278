import { isEmpty } from 'lodash';

import { parseDate, subDate } from 'common/utils/dates';
import { getFieldsInPeriod } from 'common/utils/helpers/rates';

import { DATE_FORMATS } from '../constants';
import {
    formatDateDefault,
    formatDateLabel,
    getContractedDemandFields,
    getCorrectLisaDateFormat,
    getDefaultSummerMonth,
    getEnergyFields,
    getSortedPeriods,
    parseDateDefault,
} from '../helpers';
import * as selectors from '../selectors';

import prepareConsumptions from './prepareConsumptions';
import resetConsumptionProfile from './resetConsumptionProfile';
import { updatePlaceholder } from './updatePlaceholders';

export default ({
        formValues,
        hourlyContractedDemand,
        rate,
        rateConfiguration,
    }) =>
    (dispatch, getState) => {
        const state = getState();
        const consumptionsRawData = selectors.getConsumptionsRawData(state);
        if (!consumptionsRawData) return;

        const ratesDictionary = selectors.getRatesDictionary(state);
        const summerMonths = selectors.getMonthsForSelect(state);
        const profilesConsumptionData =
            selectors.getProfileConsumptionData(state);
        const { tiers_energy_distribution } = rateConfiguration || {};
        const isBimonthly = consumptionsRawData.is_bimonthly;

        let newValues = {
            ...formValues,
            ...getContractedDemandFields(
                consumptionsRawData.contracted_demand,
                hourlyContractedDemand
            ),
            address: consumptionsRawData.address,
            cnmc_data_modified: consumptionsRawData.modified,
            consumption_profile: null,
            csv_data_modified: false,
            has_low_tension_concept: false,
            holder: consumptionsRawData.name,
            is_bimonthly: isBimonthly ? '1' : '0',
            periodicity_type: isBimonthly ? 1 : 0,
            political_division1:
                formValues?.political_division1 ||
                consumptionsRawData.political_division1,
            rate: rate.id,
            rate_division_summer: getDefaultSummerMonth({
                formValues: {},
                selectedRate: rate,
                summerMonths,
            }),
            service_number: `${consumptionsRawData.service_number || ''}`,
        };

        const totalPeriods = isBimonthly ? 6 : 12;
        const daysToSubtract = isBimonthly ? 30 : 15;

        const summaryData = getSortedPeriods(
            consumptionsRawData.values,
            DATE_FORMATS.LISA
        );

        let summary = [];
        let currDate = parseDate(summaryData[0].final_date, DATE_FORMATS.LISA);

        for (let i = 0; i < totalPeriods; i++) {
            const final_date = formatDateDefault(currDate);
            const label = formatDateLabel(
                subDate(currDate, { days: daysToSubtract })
            );
            const period = summaryData.shift();
            let newPeriod;
            if (!isEmpty(period)) {
                const initial_date = getCorrectLisaDateFormat(
                    period.initial_date
                );

                newPeriod = {
                    ...period,
                    final_date,
                    initial_date,
                    label,
                    power_factor: period.power_factor || 90,
                    ...getEnergyFields({
                        energy: period.energy,
                        final_date,
                        initial_date,
                        rate,
                        tiers_energy_distribution,
                    }),
                };

                currDate = parseDateDefault(newPeriod.initial_date);
            } else {
                currDate = subDate(currDate, { months: isBimonthly ? 2 : 1 });
                const initial_date = formatDateDefault(currDate);

                newPeriod = {
                    final_date,
                    initial_date,
                    label,
                    power_factor: 90,
                    season_change: false,
                    ...getFieldsInPeriod({
                        finalDate: final_date,
                        initialDate: initial_date,
                        rate,
                        tiers_energy_distribution,
                    }),
                };
            }

            summary.push(newPeriod);
        }

        newValues.summary = summary;

        newValues.summary = updatePlaceholder({
            formValues: newValues,
            profilesConsumptionData,
            rateId: rate.id,
            ratesDictionary,
        });
        newValues.last_consumption = summary[0].final_date;

        if (formValues?.consumption_profile)
            dispatch(resetConsumptionProfile());
        dispatch(
            prepareConsumptions({
                automaticHasChanges: true,
                initialValues: newValues,
            })
        );
    };

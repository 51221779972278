import React, { useEffect, useState } from 'react';

import FolderIcon from '@mui/icons-material/Folder';
import { push } from 'connected-react-router';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box, Grid, Tab, TabPanel, Tabs } from 'sunwise-ui';

import TitleIcon from 'common/components/TitleIcon';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { validateAccessWithPermissions } from 'common/utils/helpers/permissionsV2';
import parseQueryString from 'common/utils/parseQueryString';

import CreateProjectModal from '../createProjectModal';
import ImportConsumptionsModal from '../importConsumptionsModal';
import monitoringView from '../monitoring';
import projectConsumptionCard from '../projectConsumptionCard';
import ProjectConsumptionsModal from '../projectConsumptionsModal';
import projectDocument from '../projectDocument';
import projectLocation from '../projectLocation';
import projectWorkTabs from '../projectWorkTabs';
import reporting from '../reporting';

import * as actions from './actions';
import ProjectsContainer from './components/ProjectsContainer';
import * as selectors from './selectors';

const Container = ({
    fetchProjectDetails,
    fetchProjects,
    fetchStatusProject,
    getPermissionsByCode,
    handleClickArchive,
    handleClickRename,
    handleOpenProject,
    history,
    isFetchingProjectDetails,
    isFetchingProjects,
    location,
    match,
    prepareFormCreate,
    projectDetails,
    projects,
    projectTab,
    proposalTab,
    resetValues,
    setProjectTab,
    setProposalTab,
}) => {
    const { t } = useTranslation();
    const { search: locationSearch = '' } = location;
    const { project: projectId } = parseQueryString(locationSearch);

    const [filterData, setFilterData] = useState({
        contactId: match.params.uid,
        searchText: '',
        status: 'active',
    });

    const showProposalsTab = validateAccessWithPermissions([
        PERMISSION_LIST.COMMERCIAL_OFFER_DOCUMENTS_PERMISSION,
        PERMISSION_LIST.CONSUMPTION_HISTORY_PERMISSION,
        PERMISSION_LIST.CONTACT_PROPOSALS_PERMISSION,
        PERMISSION_LIST.PROJECT_LOCATION_PERMISSION,
        PERMISSION_LIST.PROPOSAL_SUMMARIES_PERMISSION,
    ]);

    const { canView: canViewConsumptionHistory } = getPermissionsByCode(
        PERMISSION_LIST.CONSUMPTION_HISTORY_PERMISSION
    );
    const { canView: canViewMonitoring } = getPermissionsByCode(
        PERMISSION_LIST.MONITORING_PERMISSION
    );
    const { canView: canViewProjectDocuments } = getPermissionsByCode(
        PERMISSION_LIST.COMMERCIAL_OFFER_DOCUMENTS_PERMISSION
    );
    const { canView: canViewProjectLocation } = getPermissionsByCode(
        PERMISSION_LIST.PROJECT_LOCATION_PERMISSION
    );
    const { canView: canViewProposals } = getPermissionsByCode(
        PERMISSION_LIST.CONTACT_PROPOSALS_PERMISSION
    );
    const { canView: canViewReports } = getPermissionsByCode(
        PERMISSION_LIST.AFTER_SALES_PERMISSION
    );

    useEffect(() => {
        fetchProjects(filterData);
        fetchStatusProject();
        return () => resetValues();
    }, []);

    useEffect(() => {
        const hasProjects = !isEmpty(projects);
        if (!projectId && hasProjects && isEmpty(projectDetails))
            handleOpenProject(projects[0]?.id || null);
    }, [projects]);

    useEffect(() => {
        if (projectId) fetchProjectDetails(projectId);
    }, [projectId]);

    const handleChange = (_, newValue) => {
        setProjectTab(newValue);
        const tab = newValue === 'proposals' ? '&type=proposals' : '';
        history.push({
            pathname: location.pathname,
            search: `?tab=projects&project=${projectId}&view=${newValue}${tab}`,
        });
    };

    return (
        <>
            <ProjectsContainer
                fetchProjects={fetchProjects}
                filterData={filterData}
                handleClickArchive={handleClickArchive}
                handleClickRename={handleClickRename}
                handleOpenProject={handleOpenProject}
                isLoading={isFetchingProjectDetails || isFetchingProjects}
                prepareFormCreate={prepareFormCreate}
                projects={projects}
                selectedProject={projectDetails}
                setFilterData={setFilterData}
            />

            {!isEmpty(projectDetails) && (
                <>
                    <Box mb={2}>
                        <TitleIcon
                            fontWeight="bold"
                            icon={
                                <FolderIcon
                                    fontSize="medium"
                                    sx={{ color: '#ff9a00' }}
                                />
                            }
                            title={projectDetails?.name}
                            variant="body1"
                        />
                    </Box>
                    <Tabs value={projectTab} onChange={handleChange}>
                        {showProposalsTab && (
                            <Tab
                                id="tab-proposals"
                                key="tab-proposals"
                                label={t('Proposal', { count: 2 })}
                                value="proposals"
                            />
                        )}
                        {canViewMonitoring && (
                            <Tab
                                id="tab-monitoring"
                                key="tab-monitoring"
                                label={t('Monitoring')}
                                value="monitoring"
                            />
                        )}
                        {canViewReports && (
                            <Tab
                                id="tab-reports"
                                key="tab-reports"
                                label={t('Report', { count: 2 })}
                                value="reports"
                            />
                        )}
                    </Tabs>

                    {showProposalsTab && (
                        <TabPanel
                            key="tab-proposals"
                            selectedTab={projectTab}
                            value="proposals"
                        >
                            <Grid container>
                                <Grid
                                    item
                                    xs={18}
                                    lg={
                                        canViewProjectLocation ||
                                        canViewProposals
                                            ? 9
                                            : 18
                                    }
                                >
                                    <projectConsumptionCard.Container
                                        selectedProject={projectDetails}
                                    />
                                    <projectDocument.Container
                                        projectId={projectDetails?.id}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={18}
                                    lg={
                                        canViewConsumptionHistory ||
                                        canViewProjectDocuments
                                            ? 9
                                            : 18
                                    }
                                >
                                    <projectLocation.Container
                                        selectedProject={projectDetails}
                                    />
                                    <projectWorkTabs.Container
                                        projectId={projectDetails?.id}
                                        projectName={projectDetails?.name || ''}
                                        proposalTab={proposalTab}
                                        setProposalTab={setProposalTab}
                                    />
                                </Grid>
                            </Grid>
                        </TabPanel>
                    )}

                    {canViewMonitoring && (
                        <TabPanel
                            key="tab-monitoring"
                            selectedTab={projectTab}
                            value="monitoring"
                        >
                            <monitoringView.Container
                                selectedProjectId={projectDetails?.id}
                            />
                        </TabPanel>
                    )}

                    {canViewReports && (
                        <TabPanel
                            key="tab-reports"
                            selectedTab={projectTab}
                            value="reports"
                        >
                            <reporting.Container
                                contactId={match.params.uid}
                                selectedProject={projectDetails}
                            />
                        </TabPanel>
                    )}
                </>
            )}

            <CreateProjectModal.Container
                fetchProjects={() => fetchProjects(filterData)}
                handleOpenProject={handleOpenProject}
            />

            <ProjectConsumptionsModal.Container
                fetchProjectDetails={fetchProjectDetails}
                fetchProjects={() => fetchProjects(filterData)}
            />

            <ImportConsumptionsModal.Container />
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    isFetchingProjectDetails: selectors.getIsFetchingProjectDetails,
    isFetchingProjects: selectors.getIsFetchingProjects,
    projectDetails: selectors.getProjectDetailsData,
    projects: selectors.getDataProjects,
});

const mapDispatchToProps = (dispatch) => ({
    fetchProjectDetails: (projectId) =>
        dispatch(actions.fetchProjectDetails(projectId)),
    fetchProjects: (filterData, callback) =>
        dispatch(actions.fetchProjects(filterData, callback)),
    fetchStatusProject: () => dispatch(actions.fetchStatusProject()),
    handleClickArchive: (project, status, callback) =>
        dispatch(actions.prepareBulkStatus(project, status, callback)),
    handleClickRename: (filterData, project) =>
        dispatch(actions.prepareRename(filterData, project)),
    prepareFormCreate: () => dispatch(actions.prepareFormCreate()),
    redirectTo: (url) => dispatch(push(url)),
    resetValues: () => dispatch(actions.resetValues()),
});

Container.propTypes = {
    fetchProjectDetails: PropTypes.func,
    fetchProjects: PropTypes.func,
    fetchStatusProject: PropTypes.func,
    getPermissionsByCode: PropTypes.func,
    handleClickArchive: PropTypes.func,
    handleClickRename: PropTypes.func,
    handleOpenProject: PropTypes.func,
    history: PropTypes.object,
    isFetchingProjectDetails: PropTypes.bool,
    isFetchingProjects: PropTypes.bool,
    location: PropTypes.object,
    match: PropTypes.object,
    prepareFormCreate: PropTypes.func,
    projectDetails: PropTypes.object,
    projects: PropTypes.array,
    projectTab: PropTypes.string,
    proposalTab: PropTypes.string,
    resetValues: PropTypes.func,
    setProjectTab: PropTypes.func,
    setProposalTab: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions([
        PERMISSION_LIST.AFTER_SALES_PERMISSION,
        PERMISSION_LIST.COMMERCIAL_OFFER_DOCUMENTS_PERMISSION,
        PERMISSION_LIST.CONSUMPTION_HISTORY_PERMISSION,
        PERMISSION_LIST.CONTACT_PROPOSALS_PERMISSION,
        PERMISSION_LIST.MONITORING_PERMISSION,
        PERMISSION_LIST.PROJECT_LOCATION_PERMISSION,
        PERMISSION_LIST.PROPOSAL_SUMMARIES_PERMISSION,
    ]),
    withRouter
)(Container);

import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from 'sunwise-ui';

import {
    ReactHookFormInputDatePicker,
    ReactHookFormIntlNumberInput,
    ReactHookFormIntlNumberInputConsumption,
} from 'common/components/form/bootstrap';

import {
    getBorderColor,
    getMaxInitialDate,
    handleNormalizeConsumption,
    handleNormalizeFinalDateBuild,
    handleNormalizeInitialDateBuild,
    handleNormalizeTotalKwh,
    handleOnPasteHourlyFields,
} from '../../../../helpers';
import {
    AvgConsumptionWarning,
    DaysInPeriodWarning,
    DemandRatioWarning,
    MissingFieldsAlert,
    SeasonChangeWarning,
} from '../../../warnings';

import MonthlyPDFFileUpload from './MonthlyPDFFileUpload';

const IntakeCaptureField = ({
    control,
    disabled,
    disabledConsumptionFieldsByCsv,
    getValues,
    handleDownloadLisaFile,
    handleNormalizeDates,
    handleUploadPDFReciptFile,
    index,
    maxDate,
    minDate,
    name,
    period,
    periodWarnings,
    profilesConsumptionData,
    rateConfiguration,
    ratesDictionary,
    setValue,
}) => {
    const { t } = useTranslation();
    const {
        hasAvgConsumptionWarning,
        hasDaysInPeriodWarning,
        inconsistentDemands,
    } = periodWarnings ?? {};
    const borderColor = getBorderColor(get(period, 'predicted'));
    const sxWarning = {
        border: borderColor ? `1px solid ${borderColor}` : '',
    };
    const sxCard = {
        border: hasDaysInPeriodWarning ? '1px solid #ffc107' : '',
    };

    const handleChangeKWh = (field) =>
        handleNormalizeConsumption({
            field,
            getValues,
            index,
            profilesConsumptionData,
            ratesDictionary,
            setValue,
        });
    const handleOnChangeTotal = () =>
        handleNormalizeTotalKwh({
            getValues,
            index,
            profilesConsumptionData,
            ratesDictionary,
            setValue,
        });

    return (
        <Box sx={sxCard} borderRadius={1}>
            {!disabledConsumptionFieldsByCsv && (
                <MonthlyPDFFileUpload
                    control={control}
                    disabled={disabledConsumptionFieldsByCsv}
                    handleDownloadLisaFile={handleDownloadLisaFile}
                    name={`${name}.file`}
                    onChange={(e) =>
                        handleUploadPDFReciptFile({
                            file: e,
                            getValues,
                            index,
                            rateConfiguration,
                            ratesDictionary,
                            setValue,
                        })
                    }
                    setValue={setValue}
                    url_type={period?.url_type}
                />
            )}

            <Grid container columns={12}>
                <Grid item sm xs={12}>
                    <ReactHookFormInputDatePicker
                        control={control}
                        disabled={disabledConsumptionFieldsByCsv}
                        label={t('Initial date')}
                        maxDate={getMaxInitialDate(period)}
                        minDate={minDate}
                        name={`${name}.initial_date`}
                        onChange={(value) => {
                            handleNormalizeInitialDateBuild({
                                getValues,
                                handleNormalizeDates,
                                index,
                                rateConfiguration,
                                ratesDictionary,
                                setValue,
                                value,
                            });
                        }}
                        sxInput={sxWarning}
                    />
                </Grid>

                <Grid item sm xs={12}>
                    <ReactHookFormInputDatePicker
                        control={control}
                        disabled={index > 0 || disabledConsumptionFieldsByCsv}
                        label={t('Final date')}
                        maxDate={maxDate}
                        minDate={minDate}
                        name={`${name}.final_date`}
                        onChange={() => {
                            if (index > 0) return;
                            handleNormalizeFinalDateBuild({
                                getValues,
                                handleNormalizeDates,
                                profilesConsumptionData,
                                rateConfiguration,
                                ratesDictionary,
                                setValue,
                            });
                        }}
                        sxInput={sxWarning}
                    />
                </Grid>

                <Grid item sm xs={12}>
                    <ReactHookFormIntlNumberInput
                        append="%"
                        control={control}
                        disabled={disabled}
                        fullWidth
                        label={t('Power factor')}
                        max={100}
                        min={0}
                        name={`${name}.power_factor`}
                        sx={sxWarning}
                    />
                </Grid>
            </Grid>

            <Box mb={2}>{t('Energy')}</Box>

            <Grid container>
                {Object.keys(period?.kWh ?? {}).map((key, column) => (
                    <Grid key={`period-kWh-${key}`} item xs={18} md={9} lg>
                        <ReactHookFormIntlNumberInputConsumption
                            allowDecimals={false}
                            control={control}
                            disabled={disabledConsumptionFieldsByCsv}
                            fullWidth
                            label={period?.kWh[key]?.label}
                            name={`${name}.kWh.${key}`}
                            onBlur={() => handleChangeKWh(`${name}.kWh.${key}`)}
                            onPaste={(event) =>
                                handleOnPasteHourlyFields({
                                    event,
                                    initialColumn: column,
                                    initialRow: 0,
                                    name,
                                    period,
                                    setValue,
                                })
                            }
                            sx={sxWarning}
                            tier={period?.kWh?.[key]?.tier}
                        />
                    </Grid>
                ))}

                <Grid item xs={18} md={9} lg>
                    <ReactHookFormIntlNumberInputConsumption
                        control={control}
                        disabled={disabledConsumptionFieldsByCsv}
                        fullWidth
                        label={t('Total')}
                        name={`${name}.total`}
                        onBlur={handleOnChangeTotal}
                        sx={sxWarning}
                    />
                </Grid>
            </Grid>

            <Box mb={2}>{t('Demand')}</Box>

            <Grid container>
                {Object.keys(period?.kW ?? {}).map((key, column) => (
                    <Grid key={`period-kW-${key}`} item xs={18} md={9} lg>
                        <ReactHookFormIntlNumberInputConsumption
                            control={control}
                            disabled={disabled}
                            fullWidth
                            label={period?.kW[key]?.label}
                            name={`${name}.kW.${key}`}
                            onPaste={(event) =>
                                handleOnPasteHourlyFields({
                                    event,
                                    initialColumn: column,
                                    initialRow: 1,
                                    name,
                                    period,
                                    setValue,
                                })
                            }
                            sx={sxWarning}
                            tier={period?.kW?.[key]?.tier}
                        />
                    </Grid>
                ))}
            </Grid>

            {period?.season_change_pdf && <SeasonChangeWarning />}

            <DemandRatioWarning inconsistentDemands={inconsistentDemands} />

            <DaysInPeriodWarning
                hasDaysInPeriodWarning={hasDaysInPeriodWarning}
            />

            <AvgConsumptionWarning
                hasAvgConsumptionWarning={hasAvgConsumptionWarning}
            />

            <MissingFieldsAlert period={period} />
        </Box>
    );
};

IntakeCaptureField.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    disabledConsumptionFieldsByCsv: PropTypes.bool,
    getValues: PropTypes.func,
    handleDownloadLisaFile: PropTypes.func,
    handleNormalizeDates: PropTypes.func,
    handleUploadPDFReciptFile: PropTypes.func,
    index: PropTypes.number,
    maxDate: PropTypes.object,
    minDate: PropTypes.object,
    name: PropTypes.string,
    period: PropTypes.object,
    periodWarnings: PropTypes.object,
    profilesConsumptionData: PropTypes.array,
    rateConfiguration: PropTypes.object,
    ratesDictionary: PropTypes.object,
    setValue: PropTypes.func,
};

export default IntakeCaptureField;

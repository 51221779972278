import React, { useEffect, useState } from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import WarningIcon from '@mui/icons-material/Warning';
import {
    Alert,
    BottomActions,
    Box,
    Button,
    TabPanel,
    Tabs,
} from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { ReactHookFormCheck } from 'common/components/form/bootstrap';
import ShowErrors from 'common/components/ShowErrors';
import TabBadge from 'common/components/TabBadge';
import { RATES_WITH_DIVISION } from 'common/constants/rates';
import LisaFiles from 'common/modules/lisaFiles';
import zap from 'common/modules/zap';
import { getIsMexicanAccount } from 'common/utils/helpers/session';
import yupResolver from 'common/utils/yupResolver';

import * as companyGeneralSelectors from '../../companyGeneral/selectors';
import * as actions from '../actions';
import {
    CONSUMPTIONS_CAPTURE_MODE,
    FORM_SECTIONS,
    TABS_FORM_SECTIONS,
    UPLOAD_MODES,
} from '../constants';
import {
    getConsumptionHistoryWarnings,
    getHasErrorsByFields,
    getSectionsWithErrors,
} from '../helpers';
import * as selectors from '../selectors';
import validate from '../validate';

import ChartJsComponent from './ChartJsComponent';
import ContractedDemandContainer from './ContractedDemand';
import Debouncer from './Debouncer';
import GeneralInformation from './GeneralInformation';
import IntakeManualCapture from './IntakeManualCapture';
import MissingFieldsModal from './MissingFieldsModal';
import SelectConsumptionProfile from './SelectConsumptionProfile';
import UploadTabs from './UploadTabs';
import { CNMCInconsistentAlert, IncompleteLisaResponse } from './warnings';
import LessThanOneYearWarning from './warnings/LessThanOneYearWarning';
import LowReliabilityWarning from './warnings/LowReliabilityWarning';
import MaxDaysInYearWarning from './warnings/MaxDaysInYearWarning';

const tabs = ['upload', 'general', 'energy'];

const ConsumptionsForm = ({
    CustomSaveButton,
    addZapRpuData,
    changeMonths,
    companyIsFromSpain,
    consumptionsCaptureMode,
    disabled,
    fetchLisaFiles,
    fetchProjectDetails,
    fetchProjects,
    fetchZapData,
    handleNormalizeDates,
    hasCupsInfo,
    hasMoreDaysThanAllowed,
    hasZapData,
    initialValues,
    isFromCsv,
    isLoadingZapIntegration,
    isSaving,
    profilesConsumptionData,
    ratesDictionary,
    saveConsumptions,
    setIsDisabled,
    setIsFromCreate,
}) => {
    const { t } = useTranslation();
    const [formValues, setFormValues] = useState(initialValues);
    const [selectedTab, setSelectedTab] = useState(tabs[0]);
    const [uploadMode, setUploadMode] = useState(UPLOAD_MODES.DEFAULT);
    const {
        control,
        formState: { errors, isValid, isSubmitted },
        getValues,
        handleSubmit,
        reset,
        setValue,
    } = useForm({
        context: { isFromCsv, ratesDictionary },
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    const isMexicanAccount = getIsMexicanAccount();

    useEffect(() => {
        setFormValues(initialValues);
        reset(initialValues);
    }, [initialValues]);
    const selectedRate = ratesDictionary[formValues?.rate];

    const handleOnSubmit = (values) => {
        setIsFromCreate(false);
        if (!values.id) setIsFromCreate(true);
        saveConsumptions({ ...values, ratesDictionary }, (projectId) => {
            if (fetchProjects) fetchProjects();
            fetchProjectDetails(projectId);
        });
    };

    const handleOnChangeTab = (newTab) => setSelectedTab(newTab);

    const warnings = getConsumptionHistoryWarnings(formValues, selectedRate);
    const {
        hasAvgConsumptionWarning,
        hasDaysInPeriodWarning,
        hasInconsistentDemands,
        hasMissingFields,
    } = warnings ?? {};

    const hasWarnings =
        hasInconsistentDemands ||
        hasDaysInPeriodWarning ||
        hasMissingFields ||
        hasAvgConsumptionWarning;

    const hasModifiedDates =
        hasMoreDaysThanAllowed || formValues?.csv_data_modified;

    const isFromScrapper =
        consumptionsCaptureMode === CONSUMPTIONS_CAPTURE_MODE.SCRAPPER;

    const sectionsWithErrors = getSectionsWithErrors(FORM_SECTIONS, errors);

    const hasEnergyErrors = getHasErrorsByFields({
        errors: sectionsWithErrors,
        fields: TABS_FORM_SECTIONS.ENERGY,
    });

    const isLastTab = selectedTab === tabs[tabs.length - 1];
    const isFirstTab = selectedTab === tabs[0];
    const isZapUploadModeWithoutData =
        uploadMode === UPLOAD_MODES.ZAP && !hasZapData;
    const isCupsUploadModeWithoutInfo =
        uploadMode === UPLOAD_MODES.CUPS && !hasCupsInfo;

    const hideNextButton =
        isFirstTab &&
        (isZapUploadModeWithoutData || isCupsUploadModeWithoutInfo);

    const handleClickNext = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setSelectedTab((prev) => {
            const currentIndex = tabs.indexOf(prev);
            const nextIndex = currentIndex + 1;
            return tabs[nextIndex];
        });
    };

    const handleClickBack = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setSelectedTab((prev) => {
            const currentIndex = tabs.indexOf(prev);
            const nextIndex = currentIndex - 1;
            return tabs[nextIndex];
        });
    };

    const renderCustomButton = ({ handleSubmit }) => {
        if (CustomSaveButton)
            return (
                <CustomSaveButton
                    handleSubmit={handleSubmit}
                    ratesDictionary={ratesDictionary}
                />
            );
    };

    return (
        <>
            <Debouncer control={control} setFormValues={setFormValues} />

            {disabled && (
                <Alert
                    severity="warning"
                    sx={{ '.MuiAlert-message': { width: '100%' } }}
                    variant="filled"
                >
                    <Box
                        display="flex"
                        flexWrap="wrap"
                        gap={1}
                        justifyContent="space-between"
                    >
                        {t(
                            'This project has at least one proposal generated. Do you want to modify it?'
                        )}

                        <Box
                            textAlign="right"
                            width={{ xs: '100%', md: 'auto' }}
                        >
                            <Button
                                color="secondary"
                                onClick={() => setIsDisabled(!disabled)}
                                sx={{ m: '0!important' }}
                            >
                                {t('Edit')}
                            </Button>
                        </Box>
                    </Box>
                </Alert>
            )}

            <Box borderBottom={1} borderColor="divider">
                <Tabs
                    onChange={(_, newTab) => handleOnChangeTab(newTab)}
                    value={selectedTab}
                    variant="scrollable"
                >
                    <TabBadge
                        visible={getHasErrorsByFields({
                            errors: sectionsWithErrors,
                            fields: TABS_FORM_SECTIONS.IMPORT,
                        })}
                        label={t('Import')}
                        value={tabs[0]}
                    />

                    <TabBadge
                        visible={getHasErrorsByFields({
                            errors: sectionsWithErrors,
                            fields: TABS_FORM_SECTIONS.GENERAL,
                        })}
                        label={t('General')}
                        value={tabs[1]}
                    />

                    <TabBadge
                        visible={hasEnergyErrors || hasWarnings}
                        color={
                            hasEnergyErrors || hasInconsistentDemands
                                ? 'error'
                                : 'warning'
                        }
                        label={t('Energy')}
                        value={tabs[2]}
                        disabled={
                            !selectedRate ||
                            (RATES_WITH_DIVISION.includes(selectedRate.name) &&
                                !formValues.rate_division)
                        }
                    />
                </Tabs>
            </Box>

            <form onSubmit={handleSubmit(handleOnSubmit)}>
                <TabPanel
                    key="tab-panel-upload"
                    selectedTab={selectedTab}
                    value={tabs[0]}
                >
                    <UploadTabs
                        companyIsFromSpain={companyIsFromSpain}
                        control={control}
                        disabled={disabled}
                        fileUploaded={Boolean(formValues?.file)}
                        getValues={getValues}
                        isFromScrapper={isFromScrapper}
                        isMexicanAccount={isMexicanAccount}
                        setUploadMode={setUploadMode}
                        setValue={setValue}
                        uploadMode={uploadMode}
                    />

                    {isFromScrapper && (
                        <LowReliabilityWarning warnings={warnings} />
                    )}

                    {consumptionsCaptureMode ===
                        CONSUMPTIONS_CAPTURE_MODE.LISA &&
                        formValues?.has_incomplete_files && (
                            <IncompleteLisaResponse />
                        )}

                    <LisaFiles.Container
                        onCompleteUpload={() =>
                            fetchLisaFiles({ getValues, setValue })
                        }
                    />

                    <MissingFieldsModal
                        control={control}
                        getValues={getValues}
                        selectedRate={selectedRate}
                        setValue={setValue}
                    />

                    <zap.Container
                        entity="rpu"
                        entityId={addZapRpuData?.id}
                        isLoading={isLoadingZapIntegration}
                        onFinished={() =>
                            fetchZapData({
                                getValues,
                                rpuId: addZapRpuData?.id,
                                setValue,
                            })
                        }
                    />
                </TabPanel>

                <TabPanel
                    key="tab-panel-general"
                    selectedTab={selectedTab}
                    value={tabs[1]}
                >
                    <GeneralInformation
                        control={control}
                        disabled={disabled}
                        getValues={getValues}
                        hasFormErrors={sectionsWithErrors?.GENERAL_INFORMATION}
                        hasId={Boolean(formValues?.id)}
                        isFromCsv={isFromCsv}
                        isMexicanAccount={isMexicanAccount}
                        ratesDictionary={ratesDictionary}
                        selectedRate={selectedRate}
                        setValue={setValue}
                    />
                </TabPanel>

                <TabPanel
                    key="tab-panel-energy"
                    selectedTab={selectedTab}
                    value={tabs[2]}
                >
                    {selectedRate &&
                        (!RATES_WITH_DIVISION.includes(selectedRate.name) ||
                            formValues.rate_division) && (
                            <>
                                {!isFromCsv && (
                                    <SelectConsumptionProfile
                                        control={control}
                                        disabled={disabled}
                                        getValues={getValues}
                                        handleNormalizeDates={
                                            handleNormalizeDates
                                        }
                                        hasFormErrors={
                                            sectionsWithErrors?.CONSUMPTION_PROFILE
                                        }
                                        isFromCsv={isFromCsv}
                                        profilesConsumptionData={
                                            profilesConsumptionData
                                        }
                                        ratesDictionary={ratesDictionary}
                                        setValue={setValue}
                                    />
                                )}

                                <ContractedDemandContainer
                                    companyIsFromSpain={companyIsFromSpain}
                                    control={control}
                                    disabled={disabled}
                                    hasFormErrors={
                                        sectionsWithErrors?.CONTRACTED_DEMAND
                                    }
                                    hourlyContractedDemandValues={
                                        formValues?.hourly_contracted_demand
                                    }
                                    selectedRate={selectedRate}
                                />

                                <IntakeManualCapture
                                    changeMonths={changeMonths}
                                    control={control}
                                    disabled={disabled}
                                    errors={errors}
                                    getValues={getValues}
                                    hasFormErrors={
                                        sectionsWithErrors?.CONSUMPTION_HISTORY
                                    }
                                    profilesConsumptionData={
                                        profilesConsumptionData
                                    }
                                    ratesDictionary={ratesDictionary}
                                    selectedRate={selectedRate}
                                    setValue={setValue}
                                    subsidyRate={formValues?.subsidy_rate}
                                    summary={formValues?.summary}
                                    warnings={warnings}
                                />

                                {hasModifiedDates && <MaxDaysInYearWarning />}

                                {formValues?.cnmc_data_modified && (
                                    <CNMCInconsistentAlert />
                                )}

                                <LessThanOneYearWarning
                                    summary={formValues?.summary}
                                />

                                <ChartJsComponent
                                    selectedRate={selectedRate}
                                    setValue={setValue}
                                    summary={formValues?.summary}
                                />
                            </>
                        )}

                    <ReactHookFormCheck
                        control={control}
                        disabled={disabled}
                        label={
                            <small>
                                <strong>
                                    {t(
                                        'I have reviewed the information entered, which will directly impact the calculations of my proposal'
                                    )}
                                </strong>
                            </small>
                        }
                        name="terms_consumption"
                        sxFormControl={{
                            alignItems: 'flex-end',
                            display: 'flex',
                        }}
                        sxFormLabel={{ flexDirection: 'row-reverse' }}
                    />
                </TabPanel>

                <Box mb={2} textAlign="right">
                    <small>
                        {t('The fields marked with (*) are required')}.
                    </small>
                </Box>

                {isSubmitted && !isValid && (
                    <ShowErrors
                        errors={[
                            t(
                                'Complete all fields with the correct information'
                            ),
                        ]}
                    />
                )}

                <BottomActions>
                    <Box
                        display="flex"
                        flexDirection={{ xs: 'column', sm: 'row' }}
                        gap={2}
                        mt={2}
                        width="100%"
                    >
                        <Button
                            href={`${process.env.REACT_APP_ACADEMY_URL}es/collections/2664855-consumos`}
                            rel="noreferrer"
                            startIcon={<VisibilityIcon />}
                            target="_blank"
                            variant="default"
                            sx={{
                                mr: { xs: 0, sm: 'auto' },
                                order: { xs: 3, sm: 1 },
                                textTransform: 'none',
                            }}
                        >
                            {t('Consumption manual')}
                        </Button>
                        {!isFirstTab && (
                            <Button
                                color="secondary"
                                onClick={handleClickBack}
                                sx={{ order: 2 }}
                                variant="outlined"
                            >
                                {t('Back')}
                            </Button>
                        )}
                        {!isLastTab && !hideNextButton && (
                            <Button
                                onClick={handleClickNext}
                                sx={{ order: { xs: 1, sm: 3 } }}
                            >
                                {t('Next')}
                            </Button>
                        )}
                        {isLastTab && !CustomSaveButton && (
                            <Button
                                className="__intercom_consumption_form_save_button"
                                dataIntercomTarget="Consumption form save (Button)"
                                disabled={isSaving || disabled}
                                startIcon={
                                    (hasWarnings ||
                                        (isSubmitted && !isValid)) && (
                                        <WarningIcon />
                                    )
                                }
                                sx={{ order: { xs: 1, sm: 3 } }}
                                type="submit"
                            >
                                {t('Save')}
                            </Button>
                        )}
                        {isLastTab && renderCustomButton({ handleSubmit })}
                    </Box>
                </BottomActions>
            </form>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    addZapRpuData: selectors.getAddZapRpuData,
    companyIsFromSpain: companyGeneralSelectors.getCompanyIsFromSpain,
    consumptionsCaptureMode: selectors.getConsumptionsCaptureMode,
    disabled: selectors.getIsDisabled,
    hasCupsInfo: selectors.getHasCupsInfoData,
    hasMoreDaysThanAllowed: selectors.getHasMoreDaysThanAllowed,
    hasZapData: selectors.getHasZapData,
    initialValues: selectors.getInitialValues,
    isFromCsv: selectors.getIsFromCsv,
    isLoadingZapIntegration: selectors.getIsLoadingZapIntegration,
    isSaving: selectors.getIsSavingSaveConsumptions,
    profilesConsumptionData: selectors.getProfileConsumptionData,
    ratesDictionary: selectors.getRatesDictionary,
    ratesNameDictionary: selectors.getRatesNameDictionary,
});

const mapDispatchToProps = (dispatch) => ({
    changeMonths: (config) => dispatch(actions.changeMonths(config)),
    fetchLisaFiles: (data) => dispatch(actions.fetchLisaFiles(data)),
    fetchZapData: (config) => dispatch(actions.fetchZapData(config)),
    handleNormalizeDates: (config) =>
        dispatch(actions.handleNormalizeDates(config)),
    saveConsumptions: (values, onSuccessCallback) =>
        dispatch(actions.saveConsumptions(values, onSuccessCallback)),
    setIsFromCreate: (value) => dispatch(actions.setIsFromCreate(value)),
    setIsDisabled: (isDisabled) => dispatch(actions.setIsDisabled(isDisabled)),
});

ConsumptionsForm.propTypes = {
    CustomSaveButton: PropTypes.func,
    addZapRpuData: PropTypes.object,
    changeMonths: PropTypes.func,
    companyIsFromSpain: PropTypes.bool,
    consumptionsCaptureMode: PropTypes.number,
    disabled: PropTypes.bool,
    fetchLisaFiles: PropTypes.func,
    fetchProjectDetails: PropTypes.func,
    fetchProjects: PropTypes.func,
    fetchZapData: PropTypes.func,
    handleNormalizeDates: PropTypes.func,
    hasCupsInfo: PropTypes.bool,
    hasMoreDaysThanAllowed: PropTypes.bool,
    hasZapData: PropTypes.bool,
    initialValues: PropTypes.object,
    isFromCsv: PropTypes.bool,
    isLoadingZapIntegration: PropTypes.bool,
    isSaving: PropTypes.bool,
    profilesConsumptionData: PropTypes.array,
    ratesDictionary: PropTypes.object,
    saveConsumptions: PropTypes.func,
    setIsDisabled: PropTypes.func,
    setIsFromCreate: PropTypes.func,
};

const mergeProps = (stateProps, dispatchProps, ownProps) =>
    Object.assign({}, stateProps, dispatchProps, ownProps);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ConsumptionsForm);

import get from 'lodash/get';

import { getProjectCommercialOffers } from 'common/api/v2/projectCommercialOffer';

import {
    FETCH_COMMERCIAL_OFFERS,
    FETCH_COMMERCIAL_OFFERS_FAILURE,
    FETCH_COMMERCIAL_OFFERS_SUCCESS,
} from '../actionTypes';
import { projectCommOfferTableActions } from '../reducer';

export default ({ callback, isArchived = false, projectId, status } = {}) =>
    (dispatch) => {
        dispatch(projectCommOfferTableActions[FETCH_COMMERCIAL_OFFERS]());

        getProjectCommercialOffers({ isArchived, projectId, status })
            .then((response) => {
                dispatch(
                    projectCommOfferTableActions[
                        FETCH_COMMERCIAL_OFFERS_SUCCESS
                    ](response.data.data)
                );
                if (callback) callback(response.data.data);
            })
            .catch((error) =>
                dispatch(
                    projectCommOfferTableActions[
                        FETCH_COMMERCIAL_OFFERS_FAILURE
                    ](get(error, 'data.errors', []))
                )
            );
    };

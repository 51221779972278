import { save } from 'common/api/v1/burocredito';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import fetchCommercialOffers from '../../projectCommercialOfferTable/actions/fetchCommercialOffers';
import { SAVE, SAVE_FAILURE, SAVE_SUCCESS } from '../actionTypes';
import { creditBureauCardActions } from '../reducer';

import fetchBureauData from './fetchBureauData';

export default (callback, isArchivedFilter, offerId, projectId, values) =>
    (dispatch) => {
        dispatch(creditBureauCardActions[SAVE]());

        save(values)
            .then((response) => {
                dispatch(creditBureauCardActions[SAVE_SUCCESS](response.data));

                dispatch(fetchBureauData(offerId));

                dispatch(
                    fetchCommercialOffers({
                        callback,
                        isArchived: isArchivedFilter,
                        projectId,
                    })
                );

                dispatch(alerts.actions.close());
                showToast();
            })
            .catch((error) => {
                dispatch(
                    creditBureauCardActions[SAVE_FAILURE](
                        error?.response?.data?.errors
                    )
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };

import React from 'react';

import PropTypes from 'prop-types';

import { getCountryCurrencySymbol } from 'common/utils/helpers/session';

import PowerFactorFields from '../../proposalGeneratorConfigurationModal/components/PowerFactorFields';
import PPAFields from '../../proposalGeneratorConfigurationModal/components/PPAFields';

const TabSupplier = ({ control }) => {
    const currencySymbol = getCountryCurrencySymbol();

    return (
        <>
            <PowerFactorFields
                control={control}
                showPowerFactor={true}
                isLocked={false}
            />

            <PPAFields
                control={control}
                currencySymbol={currencySymbol}
                isLocked={false}
                showPPAPercentageField={false}
            />
        </>
    );
};

TabSupplier.propTypes = {
    control: PropTypes.object,
};

export default TabSupplier;

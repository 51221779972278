import React, { useContext, useEffect } from 'react';

import { get, isEmpty, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid, UiDialog as Dialog } from 'sunwise-ui';

import commentArea from 'common/modules/commentArea';
import fileUploader from 'common/modules/fileUploader';
import { LoadingContext } from 'common/utils/contexts';

import * as actions from '../actions';
import * as selectors from '../selectors';

const FileReview = ({
    creditItem,
    isArchivedFilter,
    isUploadingFile,
    onHide,
    projectId,
    setSelectedCreditItem,
    selectedFile,
    setSelectedFile,
    show,
    showChat,
    uploadDocumentFile,
    user,
}) => {
    const loadingContext = useContext(LoadingContext);
    const proposalId = get(creditItem, 'proposalId', null);

    const { t } = useTranslation();

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isUploadingFile)
            loadingContext.openLoading(t('Uploading file').concat('...'));
        else loadingContext.closeLoading();
    }, [isUploadingFile]);

    const handleUpdateCreditStatus = (offers) => {
        const offerItem = offers.find((item) => item.id === proposalId);
        if (offerItem && !isEmpty(creditItem)) {
            const commercialOffer = get(offerItem, 'commercial_offer[0]', {});
            setSelectedCreditItem({
                ...creditItem,
                status: get(commercialOffer, 'credit_application.status', null),
            });
        }
    };

    const handleUpdateFile = (file) => setSelectedFile(file);

    const handleSend = (tempFile, onSuccess) => {
        const creditAppId = get(creditItem, 'creditAppId', null);
        const selectedFileId = get(selectedFile, 'id', null);
        if (!isNull(selectedFileId)) {
            uploadDocumentFile(
                creditAppId,
                handleUpdateCreditStatus,
                handleUpdateFile,
                isArchivedFilter,
                onSuccess,
                projectId,
                selectedFileId,
                tempFile
            );
        }
    };

    return (
        <Dialog onClose={onHide} size="xl" open={show}>
            <Grid container style={{ height: '680px' }}>
                <Grid item xs>
                    <fileUploader.Container
                        handleSend={handleSend}
                        initialFile={selectedFile.file}
                        isPreDownloadFile={selectedFile.preDownloadRequired}
                        status={selectedFile.status}
                    />
                </Grid>
                {showChat && (
                    <Grid item xs sx={{ position: 'relative' }}>
                        <commentArea.Container
                            description={selectedFile.description}
                            documentId={selectedFile.id}
                            documentType={commentArea.CREDIT_DOCUMENT_TYPE}
                            title={selectedFile.name}
                            user={user}
                            userType={commentArea.INSTALLER_USER_TYPE}
                        />
                    </Grid>
                )}
            </Grid>
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    isUploadingFile: selectors.isFileUploading,
});

const mapDispatchToProps = (dispatch) => ({
    uploadDocumentFile: (
        creditAppId,
        handleUpdateCreditStatus,
        handleUpdateFile,
        isArchivedFilter,
        onSuccess,
        projectId,
        selectedFileId,
        tempFile
    ) =>
        dispatch(
            actions.uploadDocumentFile(
                creditAppId,
                handleUpdateCreditStatus,
                handleUpdateFile,
                isArchivedFilter,
                onSuccess,
                projectId,
                selectedFileId,
                tempFile
            )
        ),
});

FileReview.propTypes = {
    creditItem: PropTypes.object,
    isArchivedFilter: PropTypes.bool,
    isUploadingFile: PropTypes.bool,
    onHide: PropTypes.func,
    projectId: PropTypes.string,
    setSelectedCreditItem: PropTypes.func,
    selectedFile: PropTypes.object,
    setSelectedFile: PropTypes.func,
    show: PropTypes.bool,
    showChat: PropTypes.bool,
    uploadDocumentFile: PropTypes.func,
    user: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(FileReview);

import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Grid, Tab, TabPanel, Tabs } from 'sunwise-ui';

import ShowErrors from 'common/components/ShowErrors';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validate';

import TabConditions from './TabConditions';
import TabGeneral from './TabGeneral';
import TabSupplier from './TabSupplier';
import TabSystem from './TabSystem';

const FormContainer = ({
    canModify,
    canModifyEnergyBackup,
    errors,
    filterData,
    handleClickCloseForm,
    handleClickSave,
    initialize,
    initialValues,
    isSaving,
}) => {
    const { t } = useTranslation();
    const { control, getValues, handleSubmit, reset, setValue, watch } =
        useForm({
            defaultValues: initialValues,
            resolver: yupResolver(validate),
        });
    const [selectedTab, setSelectedTab] = useState('general');
    const [branchOffice] = watch(['branch_office']);

    useEffect(() => reset(initialValues), [initialValues]);

    useEffect(() => {
        if (branchOffice) initialize(branchOffice);
    }, [branchOffice]);

    const handleChange = (_, newValue) => setSelectedTab(newValue);
    const handleOnSave = (values) => handleClickSave(filterData, values);

    return (
        <form>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={selectedTab} onChange={handleChange}>
                    <Tab
                        id="tab-general"
                        key="tab-general"
                        label={t('General')}
                        value="general"
                    />
                    <Tab
                        id="tab-system"
                        key="tab-system"
                        label={t('System')}
                        value="system"
                    />
                    <Tab
                        id="tab-supplier"
                        key="tab-supplier"
                        label={t('Supplier')}
                        value="supplier"
                    />
                    <Tab
                        id="tab-conditions"
                        key="tab-conditions"
                        label={t('Terms and considerations')}
                        value="conditions"
                    />
                </Tabs>
            </Box>

            <TabPanel
                key="tab-general"
                selectedTab={selectedTab}
                value="general"
            >
                <TabGeneral
                    canModifyEnergyBackup={canModifyEnergyBackup}
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    watch={watch}
                />
            </TabPanel>

            <TabPanel key="tab-system" selectedTab={selectedTab} value="system">
                <TabSystem control={control} setValue={setValue} />
            </TabPanel>

            <TabPanel
                key="tab-supplier"
                selectedTab={selectedTab}
                value="supplier"
            >
                <TabSupplier control={control} setValue={setValue} />
            </TabPanel>

            <TabPanel
                key="tab-conditions"
                selectedTab={selectedTab}
                value="conditions"
            >
                <TabConditions control={control} />
            </TabPanel>

            <ShowErrors errors={errors} />

            <Grid container mt={1}>
                <Grid
                    item
                    xs
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: { xs: 'center', md: 'right' },
                    }}
                >
                    <Button
                        color="secondary"
                        disabled={isSaving}
                        onClick={handleClickCloseForm}
                        sx={{
                            mr: { md: 2, xs: 0 },
                            order: { md: 1, xs: 2 },
                            width: { md: 'auto', xs: '100%' },
                        }}
                        variant="text"
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        disabled={isSaving}
                        onClick={handleSubmit(handleOnSave)}
                        sx={{
                            mb: { md: 0, xs: 2 },
                            order: { md: 2, xs: 1 },
                            width: { md: 'auto', xs: '100%' },
                        }}
                        type="button"
                        variant="outlined"
                        visible={canModify}
                    >
                        {t('Save')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    accessories: selectors.getFetchAccessoriesData,
    additionals: selectors.getFetchAdditionalsData,
    batteries: selectors.getFetchBatteriesData,
    errors: selectors.getSaveItemErrors,
    initialValues: selectors.getInitialValues,
    inverters: selectors.getFetchInvertersData,
    isFetchingPlan: selectors.getIsFetchingPlan,
    isInitializing: selectors.getIsInitializing,
    isNewRecord: selectors.getIsNewRecord,
    isSaving: selectors.getIsSavingItem,
    panels: selectors.getFetchPanelsData,
    planData: selectors.getFetchPlanData,
    structures: selectors.getFetchStructuresData,
    templates: selectors.getFetchTemplatesData,
    workforce: selectors.getFetchWorkforceData,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickDelete: () => dispatch(actions.prepareDelete()),
    handleClickSave: (filterData, values) =>
        dispatch(actions.save(filterData, values)),
    initialize: (branchOffice) =>
        dispatch(actions.initialFetching(branchOffice)),
});

FormContainer.propTypes = {
    canModify: PropTypes.bool,
    canModifyEnergyBackup: PropTypes.bool,
    errors: PropTypes.array,
    filterData: PropTypes.object,
    handleClickCloseForm: PropTypes.func,
    handleClickSave: PropTypes.func,
    initialize: PropTypes.func,
    initialValues: PropTypes.object,
    isSaving: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);

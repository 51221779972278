import React from 'react';

import PropTypes from 'prop-types';

import OpexFields from '../../proposalGeneratorConfigurationModal/components/OpexFields';
import RoiFields from '../../proposalGeneratorConfigurationModal/components/RoiFields';
import TimeToCommissioningFields from '../../proposalGeneratorConfigurationModal/components/TimeToCommissioningFields';

const TabSystem = ({ control, setValue }) => {
    return (
        <>
            <TimeToCommissioningFields control={control} isHidden={false} />

            <RoiFields
                canModifyRoiTaxIncentive={true}
                canModifyStockExchange={true}
                control={control}
                isLocked={false}
                nextRateData={{}}
                showSolarDegradationField={false}
                showDeprecatedField={true}
            />

            <OpexFields
                canModify={true}
                control={control}
                isLocked={false}
                setValue={setValue}
            />
        </>
    );
};

TabSystem.propTypes = {
    control: PropTypes.object,
    setValue: PropTypes.func,
};

export default TabSystem;

import { renameOffer } from 'common/api/v1/contactProject';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    RENAME_OFFER,
    RENAME_OFFER_FAILURE,
    RENAME_OFFER_SUCCESS,
} from '../actionTypes';
import { projectCommOfferTableActions } from '../reducer';

import fetchCommercialOffers from './fetchCommercialOffers';

export default (projectId, proposalId, isArchivedFilter, value) =>
    (dispatch) => {
        dispatch(projectCommOfferTableActions[RENAME_OFFER]());

        renameOffer(proposalId, {
            name: value,
        })
            .then((response) => {
                dispatch(
                    projectCommOfferTableActions[RENAME_OFFER_SUCCESS](
                        response.data
                    )
                );
                dispatch(
                    fetchCommercialOffers({
                        isArchived: isArchivedFilter,
                        projectId,
                    })
                );
                dispatch(alerts.actions.close());
                showToast();
            })
            .catch((error) => {
                dispatch(
                    projectCommOfferTableActions[RENAME_OFFER_FAILURE](
                        error?.response?.data?.errors
                    )
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };

import React, { useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Alert, Grid } from 'sunwise-ui';

import {
    ReactHookFormInput,
    ReactHookFormInputMask,
    ReactHookFormSelect2,
} from 'common/components/form/bootstrap';
import { DOMESTIC_RATES, RATES_WITH_DIVISION } from 'common/constants/rates';
import { getHasLowTensionConcept } from 'common/utils/helpers/rates';

import * as actions from '../actions';
import {
    CONSUMPTIONS_CAPTURE_MODE,
    DEFAULT_SERVICE_NUMBER_MASK,
} from '../constants';
import { handleNormalizeRate, subsidyRateValuesForSelect } from '../helpers';
import * as selectors from '../selectors';

import AccordionForm from './Accordion';

const ServiceBlockContainer = ({
    control,
    disabled,
    divisionsForSelect,
    fetchLocationsByZipCode,
    fetchPoliticalDivisions2,
    fetchRateDivisions,
    fetchRegionsByPoliticalDivision,
    fetchScheduleRateConfiguration,
    getValues,
    groupedRatesForSelect,
    hasFormErrors,
    hasId,
    isFetchingDivisions,
    isFetchingLocationsByZip,
    isFetchingPoliticalDivisions,
    isFetchingPoliticalDivisions2,
    isFetchingRates,
    isFetchingRegions,
    isFetchingSummerMonths,
    isFromCsv,
    isMexicanAccount,
    locationsByZipCode,
    monthsForSelect,
    politicalDivisions2ForSelect,
    politicalDivisionsForSelect,
    ratesDictionary,
    regionsForSelect,
    resetPDFfile,
    selectedRate,
    setConsumptionsCaptureMode,
    setValue,
}) => {
    const { t } = useTranslation();
    const options = isEmpty(groupedRatesForSelect)
        ? []
        : Object.values(groupedRatesForSelect);

    const handleSetManualConsumptions = () => {
        if (!isFromCsv)
            setConsumptionsCaptureMode(CONSUMPTIONS_CAPTURE_MODE.MANUAL);
    };
    const handleOnChangeRate = () => {
        handleSetManualConsumptions();
        handleNormalizeRate({
            calculateConsumptionWithCsvData: true,
            fetchScheduleRateConfiguration,
            getValues,
            isFromCsv,
            ratesDictionary,
            resetPDFfile,
            setValue,
        });
    };
    const [timer, setTimer] = useState(null);
    const handleChange = (callback) => {
        clearTimeout(timer);
        setTimer(setTimeout(() => callback(), 2000));
    };
    const handleOnChangeZipCode = (event) =>
        handleChange(() => {
            setValue('political_division1', null);
            setValue('political_division2', null);
            handleSetManualConsumptions();
            fetchLocationsByZipCode({
                calculateConsumptionWithCsvData: true,
                getValues,
                isFromCsv,
                rate: selectedRate,
                setValue,
                value: event.target.value,
            });
        });

    return (
        <AccordionForm
            defaultExpanded
            hasFormErrors={hasFormErrors}
            title={t('General information')}
        >
            <Grid container>
                <Grid item xs={18} md={9}>
                    <ReactHookFormInput
                        control={control}
                        disabled={disabled}
                        label={`${t('Name')} *`}
                        name="holder"
                        variant="filled"
                    />
                </Grid>

                <Grid item xs={18} md={9}>
                    <ReactHookFormSelect2
                        control={control}
                        disabled={isFetchingRates || disabled}
                        isClearable={!hasId}
                        label={`${t('Rate')} *`}
                        name="rate"
                        onChange={handleOnChangeRate}
                        options={options}
                        variant="filled"
                    />
                </Grid>

                <Grid item xs={18} md={9}>
                    <ReactHookFormInputMask
                        className="__intercom_rpu_field"
                        control={control}
                        dataIntercomTarget="RPU (Field)"
                        disabled={disabled}
                        fullWidth
                        label={t('Service number')}
                        mask={DEFAULT_SERVICE_NUMBER_MASK}
                        name="service_number"
                        variant="filled"
                    />
                </Grid>

                <Grid item xs={18} md={9}>
                    <ReactHookFormInput
                        control={control}
                        disabled={disabled}
                        fullWidth
                        label={t('Zip code')}
                        name="zip_code"
                        onChange={handleOnChangeZipCode}
                        variant="filled"
                    />
                </Grid>

                <Grid item xs={18} md={9}>
                    <ReactHookFormSelect2
                        control={control}
                        disabled={
                            isFetchingPoliticalDivisions ||
                            isFetchingLocationsByZip ||
                            disabled
                        }
                        isClearable={false}
                        isLoading={isFetchingPoliticalDivisions}
                        label={`${
                            isMexicanAccount
                                ? t('State')
                                : t('Political division of order 1')
                        } *`}
                        name="political_division1"
                        onChange={(value) => {
                            setValue('political_division2', null);
                            setValue('rate_division', null);
                            if (
                                value === `${t('State')} *` ||
                                value ===
                                    `${t('Political division of order 1')} *`
                            )
                                return;
                            fetchLocationsByZipCode({ value: null });
                            fetchRateDivisions(value);
                            fetchRegionsByPoliticalDivision(value, setValue);
                            fetchPoliticalDivisions2(value);
                            handleSetManualConsumptions();
                        }}
                        options={[
                            {
                                label: '',
                                options: politicalDivisionsForSelect,
                            },
                        ]}
                        variant="filled"
                    />
                </Grid>

                <Grid item xs={18} md={9}>
                    <ReactHookFormSelect2
                        control={control}
                        disabled={
                            isFetchingPoliticalDivisions ||
                            isFetchingPoliticalDivisions2 ||
                            isFetchingLocationsByZip ||
                            disabled
                        }
                        ignore={[
                            `${t('City')} *`,
                            `${t('Political division of order 2')} *`,
                        ]}
                        isClearable={false}
                        isLoading={isFetchingPoliticalDivisions2}
                        label={`${
                            isMexicanAccount
                                ? t('City')
                                : t('Political division of order 2')
                        } *`}
                        name="political_division2"
                        options={[
                            {
                                label: '',
                                options: !isEmpty(locationsByZipCode)
                                    ? locationsByZipCode
                                    : politicalDivisions2ForSelect,
                            },
                        ]}
                        variant="filled"
                    />
                </Grid>

                {selectedRate?.isCertified && (
                    <>
                        {RATES_WITH_DIVISION.includes(selectedRate.name) && (
                            <Grid item xs={18} md={9}>
                                <ReactHookFormSelect2
                                    control={control}
                                    disabled={
                                        isFetchingPoliticalDivisions ||
                                        isFetchingLocationsByZip ||
                                        isFetchingDivisions ||
                                        disabled
                                    }
                                    isClearable={false}
                                    isLoading={isFetchingDivisions}
                                    label={`${t('Division')} *`}
                                    name="rate_division"
                                    onChange={(value) => {
                                        if (
                                            !value ||
                                            value === `${t('Division')} *`
                                        )
                                            return;
                                        fetchScheduleRateConfiguration({
                                            calculateConsumptionWithCsvData: true,
                                            getValues,
                                            rate: selectedRate,
                                            rateDivision: value,
                                            setValue,
                                        });
                                    }}
                                    options={[
                                        {
                                            label: '',
                                            options: divisionsForSelect,
                                        },
                                    ]}
                                    variant="filled"
                                />
                            </Grid>
                        )}

                        {selectedRate.name === 'DAC' && (
                            <>
                                <Grid item xs={18} sm={9}>
                                    <ReactHookFormSelect2
                                        control={control}
                                        disabled={disabled}
                                        isClearable={false}
                                        label={`${t('Subsidized rate')} *`}
                                        name="subsidy_rate"
                                        options={[
                                            {
                                                label: '',
                                                options:
                                                    subsidyRateValuesForSelect(),
                                            },
                                        ]}
                                        variant="filled"
                                    />
                                </Grid>

                                <Grid item xs={18} sm={9}>
                                    <ReactHookFormSelect2
                                        control={control}
                                        disabled={disabled}
                                        isClearable={false}
                                        isLoading={isFetchingRegions}
                                        label={t('Region').concat(' *')}
                                        name="rate_region"
                                        options={[
                                            {
                                                label: '',
                                                options: regionsForSelect,
                                            },
                                        ]}
                                        variant="filled"
                                    />
                                </Grid>
                            </>
                        )}

                        {DOMESTIC_RATES.includes(selectedRate.name) && (
                            <Grid item xs={18} md={9}>
                                <ReactHookFormSelect2
                                    control={control}
                                    disabled={disabled}
                                    isClearable={false}
                                    isLoading={isFetchingSummerMonths}
                                    label={t('Start of summer').concat(' *')}
                                    name="rate_division_summer"
                                    options={[
                                        { label: '', options: monthsForSelect },
                                    ]}
                                    variant="filled"
                                />
                            </Grid>
                        )}

                        {getHasLowTensionConcept(selectedRate.name) && (
                            <Grid item xs={18} sm>
                                <ReactHookFormSelect2
                                    control={control}
                                    disabled={disabled}
                                    label={`2% ${t('Low tension')}`}
                                    name="has_low_tension_concept"
                                    variant="filled"
                                    options={[
                                        {
                                            label: '',
                                            options: [
                                                {
                                                    label: t('Active'),
                                                    value: true,
                                                },
                                                {
                                                    label: t('Inactive', {
                                                        context: 'male',
                                                    }),
                                                    value: false,
                                                },
                                            ],
                                        },
                                    ]}
                                />
                            </Grid>
                        )}
                    </>
                )}
            </Grid>

            {!hasId &&
                selectedRate?.isCertified &&
                RATES_WITH_DIVISION.includes(selectedRate.name) &&
                divisionsForSelect.length > 2 && (
                    <Alert severity="info">
                        <small>
                            {t(
                                'Remember to select (or confirm) the "Division" that is indicated for the established location of the receipt'
                            )}
                        </small>
                    </Alert>
                )}
        </AccordionForm>
    );
};

const mapStateToProps = createStructuredSelector({
    divisionsForSelect: selectors.getDivisionsForSelect,
    groupedRatesForSelect: selectors.getGroupedRatesForSelect,
    isFetchingDivisions: selectors.getIsFetchingDivisions,
    isFetchingLocationsByZip: selectors.getIsFetchingLocationsByZipCode,
    isFetchingPoliticalDivisions2: selectors.getIsFetchingPoliticalDivisions2,
    isFetchingPoliticalDivisions: selectors.getIsFetchingPoliticalDivisions,
    isFetchingRates: selectors.getIsFetchingRates,
    isFetchingRegions: selectors.getIsFetchingRegions,
    isFetchingSummerMonths: selectors.getIsFetchingSummerMonths,
    locationsByZipCode: selectors.getLocationsByZipCodeData,
    monthsForSelect: selectors.getMonthsForSelect,
    politicalDivisions2ForSelect: selectors.getPoliticalDivisions2ForSelect,
    politicalDivisionsForSelect: selectors.getPoliticalDivisionsForSelect,
    regionsForSelect: selectors.getRegionsForSelect,
});

const mapDispatchToProps = (dispatch) => ({
    fetchLocationsByZipCode: (config = {}) =>
        dispatch(actions.fetchLocationsByZipCode(config)),
    fetchPoliticalDivisions2: (str) =>
        dispatch(actions.fetchPoliticalDivisions2(str)),
    fetchRateDivisions: (id) => dispatch(actions.fetchRateDivisions(id)),
    fetchRegionsByPoliticalDivision: (id, setValue) =>
        dispatch(actions.fetchRegionsByPoliticalDivision(id, setValue)),
    fetchScheduleRateConfiguration: (config) =>
        dispatch(actions.handleFetchScheduleRateConfiguration(config)),
    resetPDFfile: () => dispatch(actions.resetPDFfile()),
    setConsumptionsCaptureMode: (mode) =>
        dispatch(actions.setConsumptionsCaptureMode(mode)),
});

ServiceBlockContainer.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    divisionsForSelect: PropTypes.array,
    fetchLocationsByZipCode: PropTypes.func,
    fetchPoliticalDivisions2: PropTypes.func,
    fetchRateDivisions: PropTypes.func,
    fetchRegionsByPoliticalDivision: PropTypes.func,
    fetchScheduleRateConfiguration: PropTypes.func,
    getValues: PropTypes.func,
    groupedRatesForSelect: PropTypes.object,
    hasFormErrors: PropTypes.bool,
    hasId: PropTypes.bool,
    isFetchingDivisions: PropTypes.bool,
    isFetchingLocationsByZip: PropTypes.bool,
    isFetchingPoliticalDivisions2: PropTypes.bool,
    isFetchingPoliticalDivisions: PropTypes.bool,
    isFetchingRates: PropTypes.bool,
    isFetchingRegions: PropTypes.bool,
    isFetchingSummerMonths: PropTypes.bool,
    isFromCsv: PropTypes.bool,
    isMexicanAccount: PropTypes.bool,
    locationsByZipCode: PropTypes.array,
    monthsForSelect: PropTypes.array,
    politicalDivisions2ForSelect: PropTypes.array,
    politicalDivisionsForSelect: PropTypes.array,
    ratesDictionary: PropTypes.object,
    regionsForSelect: PropTypes.array,
    resetPDFfile: PropTypes.func,
    selectedRate: PropTypes.object,
    setConsumptionsCaptureMode: PropTypes.func,
    setValue: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ServiceBlockContainer);
